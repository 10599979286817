/* stylelint-disable color-hex-length */
/* stylelint-disable no-descending-specificity */
@import '../../../../styles/core.css';

/* TODO: Привести в соответствие с новым дизайном media и ширину */
.footer {
  z-index: 10;
}

.footerRowFlex {
  display: flex;
  align-items: center;
  width: calc(100% + 48px);
  margin: 0 -24px;

  @media screen and (max-width: 768px) {
    justify-content: space-between;
    width: 100%;
    padding: 0 8px;
  }

  & > * {
    margin: 24px;
  }
}

.footerRow {
  &:first-child {
    padding: 35px 0 40px;
    background-color: #222122;
  }

  &:last-child {
    padding-top: 24px;
    padding-bottom: 80px;
    background-color: #141414;

    @media (--newTabletLandscape) {
      padding-top: 40px;
    }
  }
}

.footerLists {
  display: flex;
  justify-content: space-between;
  padding: 0 48px 0 0;
}

.footerPs {
  display: block;
}

.footerList {
  & .footerListTitle {
    position: relative;
    margin-bottom: 14px;
    padding-bottom: 14px;
    color: #ffffff;
    font: 14px / 20px Manrope, sans-serif;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    white-space: nowrap;

    &::after {
      content: '';
      position: absolute;
      bottom: -1px;
      display: block;
      width: 40px;
      height: 1px;
      background-color: #fff;
    }
  }

  & .footerListItem {
    display: block;
    color: #68686e;
    font: 400 14px / 33px Manrope, sans-serif;
    text-decoration: none;

    &:hover {
      color: #fff;
    }

    & .footerListItemIcon {
      position: absolute;
      top: 50%;
      left: -30px;
      margin-top: -6px;
    }
  }
}

.footerLeft {
  flex: 2 1;
  overflow: hidden;
}

.footerInfo {
  display: flex;
  flex: 0 0 316px;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 316px;
  min-width: 316px;
  padding: 45px 25px;
  border: 3px solid #fff;
  text-align: center;

  & .footerInfoTel {
    margin-bottom: 12px;
    color: #ffffff;
    font: 600 19px Manrope, sans-serif;
    text-decoration: none;
  }

  & .footerInfoWorkingHours,
  & .footerInfoEmail {
    color: #68686e;
    font: 400 14px Manrope, sans-serif;
  }

  & .footerInfoWorkingHours {
    margin-bottom: 18px;
  }

  & .footerInfoEmail {
    text-decoration: underline;
    cursor: pointer;
  }

  & .footerInfoSeparator {
    width: 60px;
    height: 1px;
    margin: 35px 0;
    background-color: #979797;
  }

  & .footerInfoLink {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    color: #ffffff;
    font: 14px / 20px Manrope, sans-serif;
    letter-spacing: 1.4px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    & .footerInfoLinkIcon {
      margin-right: 8px;
    }

    & svg {
      margin-right: 8px;
    }
  }
}

.footerScs {
  display: flex;
  flex-wrap: wrap;

  & .footerScsItem {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 12px 20px 12px 0;
    text-decoration: none;

    &:last-child {
      margin-right: 0;
    }
  }
}

.footerSelect {
  box-sizing: border-box;
  width: 100%;
  height: 54px;
  padding: 0;
  border: 1px solid #ffffff;
  border-radius: 4px;

  @media (--newTabletLandscape) {
    height: 65px;
    padding: 0 28px;
    border: 1px solid rgba(100, 102, 107, 0.22);
  }

  &.open {
    height: auto;
  }

  &.open {
    & .footerSelectBody {
      display: flex;
    }

    & .footerSelectValue {
      & .footerSelectValueIcon {
        color: #ffffff;
        transform: rotate(180deg);
      }
    }
  }

  & .footerSelectBody {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    max-height: 272px;
    padding: 0;
    border-top: 1px solid #ffffff;

    @media (--newTabletLandscape) {
      flex-direction: row;
      overflow-y: hidden;
      max-height: unset;
      padding: 25px 0;
      border-top: 1px solid rgba(100, 102, 107, 0.22);
    }

    & .footerSelectBodyList {
      padding: 12px;

      @media (--newTabletLandscape) {
        padding: 0;
      }

      & .footerSelectBodyListTitle {
        position: relative;
        margin-bottom: 16px;
        color: #ffffff;
        font: 14px / 20px Manrope, sans-serif;
        font-weight: 700;

        @media (--newTabletLandscape) {
          margin-bottom: 14px;
          padding-bottom: 14px;
          font-weight: 400;
          letter-spacing: 1.4px;
          text-transform: uppercase;

          &::after {
            content: '';
            position: absolute;
            bottom: -1px;
            display: block;
            width: 40px;
            height: 1px;
            background-color: #fff;
          }
        }
      }

      & .footerSelectBodyListItem {
        display: block;
        color: #ffffff;
        font: 400 14px / 42px Manrope, sans-serif;
        text-decoration: none;

        @media (--newTabletLandscape) {
          color: #68686e;
          font: 400 14px / 33px Manrope, sans-serif;
        }

        &.b {
          color: #ffffff;
          font: 14px / 20px Manrope, sans-serif;
          line-height: 33px;

          @media (--newTabletLandscape) {
            letter-spacing: 1.4px;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  & .footerSelectValue {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 52px;
    padding: 0 12px;
    color: #fff;
    outline: none;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    font-family: Manrope, sans-serif;
    line-height: 20px;
    cursor: pointer;

    @media (--newTabletLandscape) {
      height: 63px;
      padding: 0;
      font-size: 21px;
      line-height: 21px;
    }
  }
}

.footerCopyright {
  margin: 50px 0 45px;
  color: #68686e;
  font: 400 14px Manrope, sans-serif;
  text-align: center;

  & .footerCopyrightLink {
    border-bottom: 1px dotted #68686e;
    color: inherit;
  }
}

.footerInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  max-width: 1420px;
  margin: 0 auto;
  padding: 0 50px;

  &.column {
    flex-direction: column;
  }
}

.footerRowFlex {
  display: flex;
  align-items: center;
  width: calc(100% + 48px);
  margin: 0 -24px;

  @media screen and (max-width: 768px) {
    justify-content: space-between;
    width: 100%;
    padding: 0 8px;
  }

  & > * {
    margin: 24px;
  }
}

.footerRow {
  &:first-child {
    padding: 35px 0 40px;
    background-color: #222122;
  }

  &:last-child {
    padding-top: 24px;
    background-color: #141414;

    @media (--newTabletLandscape) {
      padding-top: 40px;
    }
  }
}

.footerLists {
  display: flex;
  justify-content: space-between;
  padding: 0 48px 0 0;
}

.footerPs {
  display: block;
}

.footerList {
  & .footerListTitle {
    position: relative;
    margin-bottom: 14px;
    padding-bottom: 14px;
    color: #ffffff;
    font: 14px / 20px Manrope, sans-serif;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    white-space: nowrap;

    &::after {
      content: '';
      position: absolute;
      bottom: -1px;
      display: block;
      width: 40px;
      height: 1px;
      background-color: #ffffff;
    }
  }

  & .footerListItem {
    display: block;
    color: #68686e;
    font: 400 14px / 33px Manrope, sans-serif;
    text-decoration: none;

    &:hover {
      color: #ffffff;
    }

    & .footerListItemIcon {
      position: absolute;
      top: 50%;
      left: -30px;
      margin-top: -6px;
    }
  }
}

.footerLeft {
  flex: 2 1;
  overflow: hidden;
}

.footerInfo {
  display: flex;
  flex: 0 0 316px;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 316px;
  min-width: 316px;
  padding: 45px 25px;
  border: 3px solid #ffffff;
  text-align: center;

  & .footerInfoTel {
    margin-bottom: 12px;
    color: #ffffff;
    font: 600 19px Manrope, sans-serif;
    text-decoration: none;
  }

  & .footerInfoWorkingHours,
  & .footerInfoEmail {
    color: #68686e;
    font: 400 14px Manrope, sans-serif;
  }

  & .footerInfoWorkingHours {
    margin-bottom: 18px;
  }

  & .footerInfoEmail {
    text-decoration: underline;
    cursor: pointer;
  }

  & .footerInfoSeparator {
    width: 60px;
    height: 1px;
    margin: 35px 0;
    background-color: #979797;
  }

  & .footerInfoLink {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    color: #ffffff;
    font: 14px / 20px Manrope, sans-serif;
    letter-spacing: 1.4px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    & .footerInfoLinkIcon {
      margin-right: 8px;
    }

    & svg {
      margin-right: 8px;
    }
  }
}

.footerScs {
  display: flex;
  flex-wrap: wrap;

  & .footerScsItem {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 12px 20px 12px 0;
    text-decoration: none;

    &:last-child {
      margin-right: 0;
    }
  }
}

.footerSelect {
  box-sizing: border-box;
  width: 100%;
  height: 54px;
  padding: 0;
  border: 1px solid #ffffff;
  border-radius: 4px;

  @media (--newTabletLandscape) {
    height: 65px;
    padding: 0 28px;
    border: 1px solid rgba(100, 102, 107, 0.22);
  }

  &.open {
    height: auto;
  }

  &.open {
    & .footerSelectBody {
      display: flex;
    }

    & .footerSelectValue {
      & .footerSelectValueIcon {
        color: #ffffff;
        transform: rotate(180deg);
      }
    }
  }

  & .footerSelectBody {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    max-height: 272px;
    padding: 0;
    border-top: 1px solid #ffffff;

    @media (--newTabletLandscape) {
      flex-direction: row;
      overflow-y: hidden;
      max-height: unset;
      padding: 25px 0;
      border-top: 1px solid rgba(100, 102, 107, 0.22);
    }

    & .footerSelectBodyList {
      padding: 12px;

      @media (--newTabletLandscape) {
        padding: 0;
      }

      & .footerSelectBodyListTitle {
        position: relative;
        margin-bottom: 16px;
        color: #ffffff;
        font: 14px / 20px Manrope, sans-serif;
        font-weight: 700;

        @media (--newTabletLandscape) {
          margin-bottom: 14px;
          padding-bottom: 14px;
          font-weight: 400;
          letter-spacing: 1.4px;
          text-transform: uppercase;

          &::after {
            content: '';
            position: absolute;
            bottom: -1px;
            display: block;
            width: 40px;
            height: 1px;
            background-color: #ffffff;
          }
        }
      }

      & .footerSelectBodyListItem {
        display: block;
        color: #ffffff;
        font: 400 14px / 42px Manrope, sans-serif;
        text-decoration: none;

        @media (--newTabletLandscape) {
          color: #68686e;
          font: 400 14px / 33px Manrope, sans-serif;
        }

        &.b {
          color: #ffffff;
          font: 14px / 20px Manrope, sans-serif;
          line-height: 33px;

          @media (--newTabletLandscape) {
            letter-spacing: 1.4px;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  & .footerSelectValue {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 52px;
    padding: 0 12px;
    color: #ffffff;
    outline: none;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    font-family: Manrope, sans-serif;
    line-height: 20px;
    cursor: pointer;

    @media (--newTabletLandscape) {
      height: 63px;
      padding: 0;
      font-size: 21px;
      line-height: 21px;
    }
  }
}

.footerCopyright {
  margin: 50px 0 45px;
  color: #68686e;
  font: 400 14px Manrope, sans-serif;
  text-align: center;

  & .footerCopyrightLink {
    border-bottom: 1px dotted #68686e;
    color: inherit;
  }
}

@media screen and (max-width: 767px) {
  .footerInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    max-width: 1420px;
    margin: 0 auto;
    padding: 0 16px;

    &.column {
      flex-direction: column;
    }
  }

  .footerLists {
    display: block;
  }

  .footerList {
    min-width: 140px;
    margin: 0 5px 20px;

    &:last-child {
      margin-bottom: 0;
    }

    & .footerListItem {
      & .footerListIcon {
        display: none;
      }
    }
  }

  .footerInfo {
    width: 100%;
    min-width: 300px;
    max-width: 316px;
  }
}

@media screen and (max-width: 1023px) {
  .footerInner {
    display: block;

    &.column {
      display: flex;
    }
  }

  .footerInfo {
    margin: 0 auto;
  }

  .footerScs {
    position: static;
  }

  .footerCopyright {
    margin-top: 30px;
  }
}
