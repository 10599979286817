@import '../../../../styles/core.css';


.footerScsItem {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px 20px 12px 0;
  text-decoration: none;

  &:last-child {
    margin-right: 0;
  }
}
