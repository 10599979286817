/* stylelint-disable color-hex-length */
/* stylelint-disable no-descending-specificity */
@import '../../../../styles/core.css';

.switcher {
  display: none;

  & + label + .footerSelectBody {
    display: none;
  }

  &:checked + label {
    & .footerSelectValueIcon {
      transform: rotate(180deg);
    }
  }

  &:checked + label + .footerSelectBody {
    display: flex;
  }
}

.item {
  display: block;
  color: #ffffff;
  font: 400 14px / 42px Manrope, sans-serif;
  text-decoration: none;

  @media (--newTabletLandscape) {
    color: #68686e;
    font: 400 14px / 33px Manrope, sans-serif;
  }

  &.b {
    color: #ffffff;
    font: 14px / 20px Manrope, sans-serif;
    line-height: 33px;

    @media (--newTabletLandscape) {
      letter-spacing: 1.4px;
      text-transform: uppercase;
    }
  }
}

.footerSelect {
  box-sizing: border-box;
  width: 100%;
  padding: 0;
  border: 1px solid #ffffff;
  border-radius: 4px;

  @media (--newTabletLandscape) {
    padding: 0 28px;
    border: 1px solid rgba(100, 102, 107, 0.22);
  }

  & .footerSelectBody {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    max-height: 272px;
    padding: 0;
    border-top: 1px solid #ffffff;

    @media (--newTabletLandscape) {
      flex-direction: row;
      overflow-y: hidden;
      max-height: unset;
      padding: 25px 0;
      border-top: 1px solid rgba(100, 102, 107, 0.22);
    }

    & .footerSelectBodyList {
      padding: 12px;

      @media (--newTabletLandscape) {
        padding: 0;
      }

      & .footerSelectBodyListTitle {
        position: relative;
        margin-bottom: 16px;
        color: #ffffff;
        font: 14px / 20px Manrope, sans-serif;
        font-weight: 700;

        @media (--newTabletLandscape) {
          margin-bottom: 14px;
          padding-bottom: 14px;
          font-weight: 400;
          letter-spacing: 1.4px;
          text-transform: uppercase;

          &::after {
            content: '';
            position: absolute;
            bottom: -1px;
            display: block;
            width: 40px;
            height: 1px;
            background-color: #ffffff;
          }
        }
      }

      & .footerSelectBodyListItem {
        display: block;
        color: #ffffff;
        font: 400 14px / 42px Manrope, sans-serif;
        text-decoration: none;

        @media (--newTabletLandscape) {
          color: #68686e;
          font: 400 14px / 33px Manrope, sans-serif;
        }

        &.b {
          color: #ffffff;
          font: 14px / 20px Manrope, sans-serif;
          line-height: 33px;

          @media (--newTabletLandscape) {
            letter-spacing: 1.4px;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  & .footerSelectValue {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 21px 12px;
    color: #ffffff;
    outline: none;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    font-family: Manrope, sans-serif;
    line-height: 20px;
    cursor: pointer;

    @media (--newTabletLandscape) {
      padding: 16px 0;
      font-size: 21px;
      line-height: 21px;
    }
  }
}
