@import '../../../../../../styles/core';

.headerContent {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: var(--colorBackgroundPrimary);
  padding: 0;
  border-bottom: 1px solid var(--colorBorderDefault);
  box-sizing: border-box;

  @media (--desktopSmall) {
    display: none;
  }

  @media (--tablet) {
    display: none;
  }

  & .headerContentGroups {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1538px;
    width: 100%;
    padding: 0;
    position: relative;
    box-sizing: border-box;

    @media (--desktopLarge) {
      padding: 0 75px;
    }

    @media (--desktop) {
      padding: 0 48px;
    }

    @media (--desktopSmall) {
      padding: 0 40px;
    }

    @media (--tablet) {
      padding: 0 15px;
    }
  }

  & .headerContentGroup {
    display: flex;
    align-items: center;
    position: static;

    &.wide {
      flex-basis: 100%;

    }

    & .headerContentGroupActionWide {
      width: 100%;

      & .headerContentGroupActionWideButton {
        justify-content: flex-start;
      }
    }
  }

  & .headerContentGroupGap {
    background-color: var(--colorBorderDefault);
    height: 24px;
    width: 1px;
    margin: 0 10px;
  }
}


.action {
  flex-shrink: 0;
  font-family: Manrope, sans-serif;
  position: relative;
  border: none;
  outline: none;
  box-sizing: border-box;
  margin: 0;
  background-color: transparent;
  padding: 0 16px;
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--colorTextSecondary);
  white-space: nowrap;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 1.5px;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  box-sizing: border-box;

  .actionClose {
    display: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    border: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin: 0 0 0 8px;
    color: var(--colorIconAccent);

    &:hover {
      color: var(--colorPrimary)
    }

    & svg {
      height: 18px;
      width: 18px;
    }
  }

  & .actionInput {
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    font-weight: 600;
    letter-spacing: 1.5px;
    font-size: 12px;
    color: var(--colorTextSecondary);
    font-family: Manrope, sans-serif;
    box-sizing: border-box;

    &::placeholder {
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 1.5px;
      font-size: 12px;
    }

    &:not(:placeholder-shown) + .actionClose {
      display: flex;
    }
  }

  & .actionIcon {
    position: static;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin: 0 8px 0 0;
    color: var(--colorIconAccent);

    & svg {
      height: 18px;
      width: 18px;
      fill: var(--colorIconAccent);
    }
  }


}
