@import '../../../../styles/core.css';

.root {
  background-color: #ffffff;

  &.headerMode {
    @media (--newDesktopSmall) {
      display: none;
    }
  }
}
