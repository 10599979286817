@import '../../../../../../styles/core.css';

.headerDropdownAction {
  height: 48px;
  flex-shrink: 0;
  font-family: Manrope, sans-serif;
  position: static;

  & .headerDropdownActionTitle {
    font-family: Manrope, sans-serif;
    border: none;
    outline: none;
    box-sizing: border-box;
    margin: 0;
    background-color: transparent;
    padding: 0 12px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--colorTextSecondary);
    white-space: nowrap;
    cursor: pointer;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    text-decoration: none;
  }

  & .headerDropdownActionIcon {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin: 0 4px 0 0;
    color: var(--colorIconAccent);
    box-sizing: border-box;

    & svg {
      height: 18px;
      width: 18px;
    }
  }

  &.warning {

    & .headerDropdownActionIconTitle {
      color: var(--colorNegative);
    }
  }

  &.normal {
    & .headerDropdownActionTitle {
      border-top: 2px solid transparent;
      border-bottom: 2px solid transparent;


      &:hover {
        background-color: var(--colorHover);

        & .headerDropdownActionIconTitle {
          color: var(--colorBackgroundAccent);
        }
      }
    }
  }

  &.active {
    & .headerDropdownActionTitle {
      border-bottom: 2px solid var(--colorPrimary);
    }

    & .headerDropdownActionIconTitle {
      color: var(--colorPrimary);
    }
  }

  &.icon {
    height: 100%;
    position: relative;

    &.active {
      border: none;

      & .headerDropdownActionIconTitle {
        color: var(--colorPrimary);
      }
    }

    & .headerDropdownActionTitle {
      padding: 0 8px;
      flex-direction: column;
      letter-spacing: normal;
      text-transform: none;
      height: 100%;
      border: none;

      @media (--desktopSmall) {
        padding: 0;
      }

      &:hover {
        & .headerDropdownActionIconTitle {
          color: var(--colorTextPrimary);
        }

        & .headerDropdownActionIcon {
          color: var(--colorHover);

          & svg {
          }

          & .headerDropdownActionBadge {
            background-color: var(--colorHover);
          }
        }
      }
    }

    & .headerDropdownActionIcon {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      margin: 0;
      color: var(--colorIconAccent);

      @media (--desktopSmall) {
        padding: 8px;
        width: 40px;
        height: 40px;
        color: var(--newColorIconPrimary);
      }


      & svg {

        @media (--desktopSmall) {
          fill: none;
        }
      }

      & .headerDropdownActionBadge {
        border-radius: 50%;
        height: 16px;
        padding: 0 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 16px;
        background-color: var(--colorIconAccent);
        position: absolute;
        top: 0;
        right: -5px;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 10px;
        color: var(--colorWhite);
        box-sizing: border-box;
      }
    }

    & .headerDropdownActionIconTitle {
      margin: 10px 0 0 0;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: unset;
      text-align: center;
      color: var(--colorTextSecondary);

      @media (--desktopSmall) {
        display: none;
      }
    }
  }
}
