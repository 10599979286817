@import '../../../../../../styles/core.css';

.headerDropdownMenu {
    height: 48px;
    flex-shrink: 0;
    font-family: Manrope, sans-serif;
    position: static;
    margin: 0 30px 0 0;

    & .headerDropdownActionTitle {
        border: none;
        outline: none;
        box-sizing: border-box;
        margin: 0;
        background-color: transparent;
        padding: 0 16px;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--colorTextSecondary);
        white-space: nowrap;
        cursor: pointer;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        text-decoration: none;
    }

    & .headerDropdownActionIcon {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        margin: 0 4px 0 0;
        color: var(--colorIconAccent);

        & svg {
            height: 18px;
            width: 18px;
        }
    }

    &.normal {
        & .headerDropdownActionTitle {
            border-top: 2px solid transparent;
            border-bottom: 2px solid transparent;


            &:hover {
                background-color: var(--colorHover);

                & .headerDropdownActionIconTitle {
                    color: var(--colorBackgroundAccent);
                }
            }
        }
    }

    &.active {
        & .headerDropdownActionTitle {
            background-color: var(--colorHover);

            & .headerDropdownActionIconTitle {
                color: var(--colorBackgroundAccent);
            }
        }
    }
}

.dropdown {
    display: flex;
    width: 100%;
    height: 100%;

    & .dropdownItems {
        width: 216px;
        flex-shrink: 0;
        padding: 18px 0;
        background-color: var(--colorBackgroundPrimary);
    }

    & .dropdownItem {
        display: block;
        height: 32px;
        width: 100%;
        padding: 0 0 0 20px;
        text-align: left;
        border: none;
        outline: none;
        text-transform: uppercase;
        background-color: transparent;
        font-family: Manrope, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 32px;
        letter-spacing: 1px;
        color: var(--colorTextSecondary);
        cursor: pointer;

        &:hover {
            color: var(--colorTextAccent);
        }

        &.active {
            color: var(--colorTextAccent);
        }

        & .linkIcon {
            display: inline;

            &.right {
                margin: 0 0 0 4px;
            }
        }

        & span {
            text-transform: lowercase;
            letter-spacing: normal;
            font-size: 12px;
        }

        &.blackFriday {
            background-color: black;
            color: var(--colorWhite);
        }

        &.newYear {
        }
    }
}
