@import '../../../../../../styles/core.css';

.root {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 48px;
  z-index: 100000000;
  background: rgba(238, 240, 251, 0.75);
  cursor: pointer;
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 2px 24px;
  box-shadow: 2px 8px 15px 0 rgba(0, 0, 0, 0.15);

  @media (--newTablet) {
    display: flex;
  }

  &.headerMode {
    display: flex;
    position: relative;
    left: unset;
    right: unset;
    bottom: unset;
    background: rgba(238, 240, 251, 0.75);
    padding: 0 16px;
    box-shadow: none;

    & .icon {
      display: none;
    }

    & .title {
      display: inline;

      .nextIcon {
        display: inline;
        width: 16px;
        height: 16px;
        position: relative;
        bottom: -2px;
      }
    }
  }
}

.closeButton {
  cursor: pointer;
  outline: none;
  border: none;
  background: none;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
  }
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--priority-primary-80, #2d3fc0);
  text-decoration: none;

  & svg {
    width: 24px;
    height: 24px;
    margin: 0 0 0 8px;
  }

  & .icon {
    width: 32px;
    height: 32px;
    margin: 0 8px 0 0;
    color: var(--priority-primary-80, #2d3fc0);
  }
}
